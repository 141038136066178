import React from 'react'

const About = () => {
  return (
    <>
    
    <div className="container" id='aboutUs' style={{paddingTop:"85px"}} >
      <div className="row">
     
        <div className="col-lg-5 col-md-5 col-sm-12" style={{paddingTop:"2%"}}>
        <h1>About Us </h1>
       
        <p style={{textAlign:"justify"}}>We are a research-based firm that focuses on writing services such as content writing, ghostwriting, and technical writing.
           CUBIX TECH is the top in the industry, with experienced authors, specialists, and professionals providing high-quality services.
          
          We believe in using a creative and technological method to translate thoughts, ideas, or facts into words or material.
           The writers and specialists give their all to every work, and our primary goal is to provide our customers with plagiarism-free
            writing. Content writing, blogs, technical writing, 
          product descriptions, website content, ghostwriting, fiction writing, eBooks, and other services are among the services we provide.</p>
         
        </div>
        <div className="col-lg-7 col-md-7 col-sm-12 my-4">
        <div classNames="box" style={{borderLeft:"10px solid orange",borderBottom:"10px solid orange"}}>
        <img src="table_talks.webp" alt='img' className='shadow-lg p-2 mb-1 rounded' style={{height:"100%",width:"100%"}}/>
         </div>
           

        </div>
      

      </div>
    </div>

   
  
  
    </>
  )
}

export default About
