import React from 'react'

const Footer = () => {
  return (
    <>
      <div className="container-fluid">
  <div className="row">
    <div className="col-12 shadow-lg p-3 mb-1 bg-orange rounded" style={{backgroundColor:"orange"}}>
      <h4 className='text-center' style={{color:"white"}}>Follow Us</h4>
      <h2 className='social text-center'><a href='https://cdrwriteraustralia.com/' target="_blank" rel='noreferrer'><i class="fa-brands fa-facebook-square"></i></a> <a href='https://cdrwriteraustralia.com/' target="_blank" rel='noreferrer'><i class="fa-brands fa-linkedin"></i></a> <a href='https://cdrwriteraustralia.com/' target="_blank" rel='noreferrer'><i class="fa-brands fa-whatsapp-square"></i></a></h2>
      <p style={{textAlign:"center"}}><b>All right reserved ©2022-CubixTech</b> </p>
    </div>
  </div>
</div>
    </>
  )
}

export default Footer
