import React from 'react'
import { Switch } from 'react-router-dom'
import { Route } from 'react-router-dom'
import {BrowserRouter as Router } from 'react-router-dom'
import About from './About'
import Contact from './Contact'
import Depart from './Depart'
import Footer from './Footer'
import Home from './Home'
import Nav from './Nav'

const App = () => {
  return (
    <>
    <Router>
      <Nav/>
      <Switch>
        <Route exact path='/' component={Home}>
          <Home/>
          </Route>
          <Route exact path='/about' component={About}>
          <About/>
          </Route>
          <Route exact path='/depart' component={Depart}>
          <Depart/>
          </Route>
          <Route exact path='/contact' component={Contact}>
          <Contact/>
          </Route>
         
      </Switch>
      <Footer/>
    </Router>
    
    </>
  )
}

export default App
