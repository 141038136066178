import React from 'react'

const Contact = () => {
  return (
    <>
      
    <div className="container pt-5" id="contact">
    <div className="row">
      <div className="col-lg-6 col-md-6 col-sm-12 pt-5">
        <h1>Contact Us</h1>
        
        <div className="info pt-5">
      
       <h4>Contact Info</h4>
      
      
      <p><i className="fa-solid fa-envelope"></i> info@cubixtechglobal.com</p>
      </div>
    </div>

      <div className="col-lg-6 col-md-6 col-sm-12 pt-5">
      <h1 className='pb-4 text-center'>Leave Us a Message!!!!</h1>
        <form action="https://formcarry.com/s/vlJz4cTu6fQ" className="shadow-lg p-3 mb-5 bg-white rounded" method="post" target="_blank" >
          <input type="text" id="fname" className="form" name="uname" placeholder="Enter your name" required/>
          <input type="text" id="lname" className="form"  name="email" placeholder="Email" required/>
          <textarea id="subject" type="text" name="message" rows="4"  className="form" placeholder="Message..."/>
         <button type="submit" className="buttons" value="submit">Submit</button>
        </form>
      </div>
    </div>
  </div>

</>
  )
}

export default Contact
