import React from 'react'
import { Link } from 'react-router-dom'
const Nav = () => {
  return (
    <>
 <nav className="navbar navbar-expand-lg navbar-light bg-warning fixed-top mr p-2">
  <button className="navbar-toggler navbar-toggler-right bg-white" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <Link className="navbar-brand" style={{color:"white"}} to="/"><img  id="logo" src="logo.webp" className=""  alt="logo" style={{height:"45px",width:"65px"}}/>CubixTech</Link>
 
  <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
    <ul className="navbar-nav ml-auto mt-2 mt-md-0">
     
      <li className="nav-item-active bg-info">
        <Link  className="nav-link" to="/" data-toggle="collapse" data-target="#navbarTogglerDemo02" style={{color:"white"}}>Home <span className="sr-only">(current)</span></Link>
      </li>
      <li className="nav-item">
        <Link activeClassName='active-link' className="nav-link" to="/about" data-toggle="collapse" data-target="#navbarTogglerDemo02" style={{color:"white"}}>About</Link>
      </li>
      <li className="nav-item">
        <Link activeClassName='active-link' className="nav-link" to="/depart" data-toggle="collapse" data-target="#navbarTogglerDemo02" style={{color:"white"}}>Departments</Link>
      </li>
      <li className="nav-item">
        <Link activeClassName='active-link' className="nav-link" to="/contact" data-toggle="collapse" data-target="#navbarTogglerDemo02" style={{color:"white"}}>Contact</Link>
      </li>
    </ul>
    
  </div>
</nav>

   
    </>
  )
}

export default Nav
