import React from 'react'

const Depart = () => {
  return (
    <>
      <div className="container" id="departments" style={{paddingTop:"85px"}}>
      <h1 style={{textAlign:"center"}}>Our Departments</h1>
    <div className="row">
      


      <div className="department col-lg-4 col-md-4 col-sm-12 g-5 pt-5" >
      <div className='box shadow-lg p-3 mb-5 bg-white rounded' style={{borderRight:"7px solid orange",borderBottom:"7px solid orange"}}>
        <h2 className="content_writer text-center">Developer</h2>
          <p style={{textAlign:"justify",padding:"10px"}}>We have access to the best developers in the industry. From individual engineers to fully-featured development teams, 
            we have been efficiently working for international organizations for apps development.
          </p>
          </div>
      </div>

      <div className="department col-lg-4 col-md-4 col-sm-12 g-5 pt-5" >
        <div className='box shadow-lg p-3 mb-5 bg-white rounded' style={{borderRight:"7px solid orange",borderBottom:"7px solid orange"}}>
        <h2 className="content_writer text-center">Content Writer</h2>
        <p style={{textAlign:"justify",padding:"10px"}}>Cubixtech has a team of freelance writers who have a keen interest in content creation. 
          With the concept of ghostwriting and correct content creation, Our expert content writers supply with Blogs,
           Technical Writing, Website content, Fiction writing, Ghost writing, 
          and more services. We develop material that is well-researched, interesting, and relevant to the target audience.
        </p>
        </div>
      </div>
     
      <div className="department col-lg-4 col-md-4 col-sm-12 g-5 pt-5" >
      <div className='box shadow-lg p-3 mb-5 bg-white rounded' style={{borderRight:"7px solid orange",borderBottom:"7px solid orange"}}>
        <h2 className="content_writer text-center">SEO</h2>
          <p style={{textAlign:"justify",padding:"10px"}}>Our SEO department performs in-depth research to determine how your customers use search engines 
            to find the items or services you offer. Our SEO department crafts a complete plan to get the best results for
             the business after reviewing this information and doing a full competition study. 
            This group of people is committed to putting together a well-thought-out strategy that will yield demonstrable outcomes.
          </p>
          </div>
      </div>

    </div>
  </div>


    </>
  )
}

export default Depart
