import React from 'react'

const Home = () => {
  return (
    <>
       <div className="container" style={{paddingBottom:"10%"}}>
      <div className="row">
  
        <div className="col-lg-5 col-md-5 col-sm-12 my-3" style={{paddingTop:"20%"}}>
  
          <h1 style={{textAlign:"center"}}>CubixTech.</h1>
          <p style={{textAlign:"center"}}>
          A freelancing and desigining enterprise.
          </p>
          <div className="button" style={{textAlign:"center"}}>
         <a href="tel:+977 9823597749" target="_blank" rel='noreferrer'><button className="btn1 shadow-lg p-2 mb-2 bg-red rounded">Contact US</button></a> 
          <a href="https://forms.gle/BaQErUrCeirTJqWL8" target="_blank" rel='noreferrer'><button className="btn1 shadow-lg p-2 mb-2 bg-green rounded">Apply for a job</button></a>

          </div>
          
        </div>
        <div className="col-lg-7 col-md-7 col-sm-12 pt-5" >
        
            <img src="table_talks.webp" alt='img' style={{height:"100%",width:"100%"}}/>
           

        </div>
      

      </div>
    </div>

    </>
  )
}

export default Home

